var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#097392","spinner":"bar-fade-scale"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#097392","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-left":"","align-self-center":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),(_vm.gallery)?_c('v-layout',{style:(_vm.$vuetify.breakpoint.name == 'xs'
        ? 'padding-top:0px !important;'
        : 'padding-top:100px !important;'),attrs:{"wrap":"","justify-center":"","pa-12":"","pa-xl-16":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[(_vm.gallery.length > 0)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-center":""}},[_c('span',{staticClass:"gallhead",staticStyle:{"border-bottom":"4px solid #bc4639","border-top":"4px solid #bc4639"}},[_vm._v("GALLERY")])]),_c('v-flex',{attrs:{"xs12":"","py-8":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","lg12":"","pa-1":""}},[_c('v-card',{attrs:{"tile":"","flat":"","color":"rgba(0,0,0,0)","elevation":"0"}},[_c('viewer',{key:_vm.gallery.length,staticStyle:{"display":"flex"},attrs:{"images":_vm.gallery}},[_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.gallery),function(item,i){return _c('v-flex',{key:i,attrs:{"align-self-center":"","xs6":"","px-2":"","px-lg-1":"","px-xl-1":"","sm6":"","md6":"","lg4":"","xl2":""}},[_c('img',{staticStyle:{"object-fit":"contain","cursor":"pointer"},attrs:{"width":"100%","height":_vm.$vuetify.breakpoint.name == 'sm'
                            ? '215px'
                            : _vm.$vuetify.breakpoint.name == 'xs'
                            ? '90px'
                            : _vm.$vuetify.breakpoint.name == 'lg'
                            ? 'auto'
                            : _vm.$vuetify.breakpoint.name == 'md'
                            ? '308px'
                            : '268px',"src":_vm.mediaURL + item.image}})])}),1)],1)],1)],1)],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }